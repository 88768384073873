import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Dashboard = Loadable(lazy(() => import("./Dashboard")));

const dashboardRoutes = [
    {
        path: '/utama/index',
        element: <Dashboard />,
    },
    {
        path: '/utama',
        element: <Dashboard />,
    },
]

export default dashboardRoutes
