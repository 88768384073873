import { goServerUrl } from 'app/utils/constant'
import axios from 'axios.js'

const navigations = async () => {
    const token = window.localStorage.getItem('accessToken')
    let nv = [{
        name: 'Dashboard',
        path: '/utama/index',
        icon: 'dashboard',
    },]
    if (token) {
        const response = await axios.get(goServerUrl + '/user/navigation', { headers: { Authorization: `Bearer ${token}` } })
        if (response.status === 200) {
            nv = response.data.data
        }
    }
    return nv
}

export { navigations }
