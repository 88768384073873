import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Profile = Loadable(lazy(() => import("./Profile")));

const profileRoutes = [
    {
        path: '/profile',
        element: <Profile />,
    },
]

export default profileRoutes
