import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const ProdukMaster = Loadable(lazy(() => import("./Master")))

const produkRoutes = [
    {
        path: '/produk/index',
        element: <ProdukMaster />,
    },
    {
        path: '/produk',
        element: <ProdukMaster />,
    },
]

export default produkRoutes
