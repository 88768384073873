import { navigations } from 'app/navigations'
import { SET_USER_NAVIGATION } from '../actions/NavigationAction'

// let initialState = [...navigations().then((res) => { return res })]

const NavigationReducer = async function (state, action) {
    navigations().then((state) => {
        if (state === undefined) {
            state = []
        }
        switch (action.type) {
            case SET_USER_NAVIGATION: {
                return [...action.payload]
            }
            default: {
                return [...state]
            }
        }
    })
    
}

export default NavigationReducer
