import React from 'react'
import { Alert, IconButton, Icon, Snackbar } from '@mui/material'

const MatxSnackbar = ({
    open,
    message,
    severity = "success",
    duration = 6000,
    handleClose,
}) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <Icon>close</Icon>
                </IconButton>,
            ]}
        >
            <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}
                    variant="filled"
                >
                    {message}
                </Alert>
        </Snackbar>
    )
}

export default MatxSnackbar
