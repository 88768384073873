import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const StockKeluarMaster = Loadable(lazy(() => import("./Master")));

const StockKeluarRoutes = [
    {
        path: '/stock_keluar/index',
        element: <StockKeluarMaster />,
    },
    {
        path: '/stock_keluar',
        element: <StockKeluarMaster />,
    },
]

export default StockKeluarRoutes
