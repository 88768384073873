import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const LaporanRekapPenjualan = Loadable(lazy(() => import("./RekapPenjualan")));
const LaporanRekapPenyesuaian = Loadable(lazy(() => import("./RekapPenyesuaian")));
const LaporanRekapQC = Loadable(lazy(() => import("./RekapQC")));
const LaporanNilaiStock = Loadable(lazy(() => import("./NilaiStock")));
const LaporanRekapPerSKU = Loadable(lazy(() => import("./RekapPerSKU")));
const LaporanRekapPerPembeli = Loadable(lazy(() => import("./RekapPerPembeli")));

const LaporanRoutes = [
    {
        path: '/laporan/rekap_penjualan',
        element: <LaporanRekapPenjualan />,
    },
    {
        path: '/laporan/rekap_penyesuaian',
        element: <LaporanRekapPenyesuaian />
    },
    {
        path: '/laporan/rekap_qc',
        element: <LaporanRekapQC />
    },
    {
        path: '/laporan/nilai_stock',
        element: <LaporanNilaiStock />
    },
    {
        path: '/laporan/rekap_per_sku',
        element: <LaporanRekapPerSKU />
    },
    {
        path: '/laporan/rekap_per_pembeli',
        element: <LaporanRekapPerPembeli />
    },
]

export default LaporanRoutes
