import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const SupplierMaster = Loadable(lazy(() => import("./Master")))

const supplierRoutes = [
    {
        path: '/supplier/index',
        element: <SupplierMaster />,
    },
    {
        path: '/supplier',
        element: <SupplierMaster />,
    },
]

export default supplierRoutes
