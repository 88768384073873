import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PenyesuaianMaster = Loadable(lazy(() => import("./Master")))

const PenyesuaianRoutes = [
    {
        path: '/penyesuaian/index',
        element: <PenyesuaianMaster />,
    },
    {
        path: '/penyesuaian',
        element: <PenyesuaianMaster />,
    },
]

export default PenyesuaianRoutes
