import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const StockMasukMaster = Loadable(lazy(() => import("./Master")))

const StockMasukRoutes = [
    {
        path: '/stock_masuk/index',
        element: <StockMasukMaster />,
    },
    {
        path: '/stock_masuk',
        element: <StockMasukMaster />,
    },
]

export default StockMasukRoutes
