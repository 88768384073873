import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PelaksanaanTugasMaster = Loadable(lazy(() => import("./Master")))

const PelaksanaanTugasRoutes = [
    {
        path: '/pelaksanaan_tugas/index',
        element: <PelaksanaanTugasMaster />,
    },
    {
        path: '/pelaksanaan_tugas',
        element: <PelaksanaanTugasMaster />,
    },
]

export default PelaksanaanTugasRoutes
