import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'

import pengaturanRoutes from 'app/views/pengaturan/PengaturanRoutes'
import produkRoutes from 'app/views/produk/ProdukRoutes'
import supplierRoutes from 'app/views/supplier/SupplierRoutes'
import pembeliRoutes from 'app/views/pembeli/PembeliRoutes'
import stockMasukRoutes from 'app/views/stock_masuk/StockMasukRoutes'
import stockKeluarRoutes from 'app/views/stock_keluar/StockKeluarRoutes'
import penyesuaianRoutes from 'app/views/penyesuaian/PenyesuaianRoutes'
import pelaksanaanTugasRoutes from 'app/views/pelaksanaan_tugas/PelaksanaanTugasRoutes'
import laporanRoutes from 'app/views/laporan/LaporanRoutes'
import profileRoutes from 'app/views/profile/ProfileRoutes'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...dashboardRoutes, ...produkRoutes, ...supplierRoutes, ...pembeliRoutes, ...stockMasukRoutes, ...stockKeluarRoutes, ...penyesuaianRoutes, ...pelaksanaanTugasRoutes, ...laporanRoutes, ...pengaturanRoutes, ...profileRoutes],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="utama/index" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
