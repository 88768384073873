import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PembeliMaster = Loadable(lazy(() => import("./Master")))

const pembeliRoutes = [
    {
        path: '/pembeli/index',
        element: <PembeliMaster />,
    },
    {
        path: '/pembeli',
        element: <PembeliMaster />,
    },
]

export default pembeliRoutes
